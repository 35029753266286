import React, { useState } from "react";

const Contact = () => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    message: "",
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const data = {
      fullName: `${formData.firstName} ${formData.lastName}`,
      email: formData.email,
      message: `Phone and Timezone: ${formData.phone}\n\nDescription:\n${formData.message}`,
    };

    fetch("https://www.eventcat.com/api/v1/contact/xl8", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((responseData) => {
        setFormData({
          firstName: "",
          lastName: "",
          email: "",
          phone: "",
          message: "",
        });
        alert("Submit success");
      })
      .catch((error) => {
        alert("There was a problem with the fetch operation:", error);
      });
  };

  return (
    <div className="flex flex-col gap-5 mx-4 sm:mx-8 lg:mx-16 xl:mx-32 my-8 pb-16">
      <div
        id="head"
        className="grid grid-cols-1 md:grid-cols-2 gap-8 mt-10 font-['Work_Sans']"
      >
        <div className="text-center md:text-left">
          <h1 className="text-2xl md:text-3xl font-bold text-gray-800">
            Call for transcription or <br /> translation.
          </h1>
          <p className="text-lg md:text-xl text-gray-600 mt-5 max-w-full md:max-w-lg">
            Thank you for your interest. For questions or project description,
            please fill in the information requested. We look forward to hearing
            from you soon.
          </p>
          {/* Contact Info */}
          <div className="mt-5 text-base md:text-lg flex flex-col gap-4">
            <p>
              <strong>Phone:</strong>
              <br /> (727) 535-1066
            </p>
            <p>
              <strong>Fax:</strong>
              <br /> (727) 530-3593
            </p>
            <p>
              <strong>Hours of Operation:</strong>
              <br /> Monday - Friday: 8:00 a.m. - 4:00 p.m. (Eastern)
            </p>
            <p>
              <strong>Customer Contact Hours:</strong>
              <br /> 24/7 Services Available
            </p>
            <p>
              <strong>Address:</strong>
              <br /> 2708 ALT US 19., N, Suite 505-AB, Palm Harbor, FL 34683
            </p>
            <p>
              <strong>Service Area:</strong>
              <br /> Worldwide
            </p>
          </div>
        </div>

        {/* Contact Form */}
        <form onSubmit={handleSubmit} className="flex flex-col gap-4">
          <div>
            <label className="block text-gray-700">First Name</label>
            <input
              type="text"
              name="firstName"
              value={formData.firstName}
              onChange={handleChange}
              required
              className="w-full px-4 py-2 border rounded-lg bg-gray-100"
              placeholder="Jane"
            />
          </div>
          <div>
            <label className="block text-gray-700">Last Name</label>
            <input
              type="text"
              name="lastName"
              value={formData.lastName}
              onChange={handleChange}
              required
              className="w-full px-4 py-2 border rounded-lg bg-gray-100"
              placeholder="Smith"
            />
          </div>
          <div>
            <label className="block text-gray-700">Email</label>
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
              className="w-full px-4 py-2 border rounded-lg bg-gray-100"
              placeholder="your@email.com"
            />
          </div>
          <div>
            <label className="block text-gray-700">Phone Number</label>
            <input
              type="tel"
              name="phone"
              value={formData.phone}
              onChange={handleChange}
              required
              className="w-full px-4 py-2 border rounded-lg bg-gray-100"
              placeholder="(000) 000-0000"
            />
          </div>
          <div>
            <label className="block text-gray-700">Message</label>
            <textarea
              name="message"
              value={formData.message}
              onChange={handleChange}
              required
              className="w-full px-4 py-2 border rounded-lg bg-gray-100"
              placeholder="Your message..."
              rows="4"
            />
          </div>
          <button
            type="submit"
            className="bg-[#001f54] text-white px-4 py-2 rounded-lg hover:bg-[#002e73] transition duration-300"
          >
            Submit
          </button>
        </form>
      </div>
    </div>
  );
};

export default Contact;
